import React from "react";
import { TextContainer, Paragraph } from "..";
import { DocPageMeta, HeaderSection, ContentSection, Header } from "../../../src/docs";
import { Code, CodeBlock } from "../../../src/docs/docs-code";

export const Meta: DocPageMeta = {
	category: "Context",
	title: "Fancy Context",
};

export default (): JSX.Element => {
	return (
		<>
			<HeaderSection
				title="Using Fancy Context"
				subTitle="This guide describes what Fancy Context is and how to use it."
			/>
			<ContentSection>
				<TextContainer article>
					<Header.H2>Intro</Header.H2>
					<Paragraph>
						FancyLib provides a react context that must be wrapped at an outer level of where fancy
						components are used. For now the context is used to identify the chosen language for
						translations. The context also inserts an element that applies the global styles from
						FancyLib to all decendendant. The global styles are required to apply things like fonts
						to fancy and non-fancy components inside a fancy application.
					</Paragraph>
					<CodeBlock language="tsx">
						{`import React from "react";
import { FancyContextProvider } from "@siteimprove/fancylib";
import "@siteimprove/fancylib/global.css"; // Global styles for general styling

export const App: React.FC = () => {
	return (
		<div className="fancy-root">
			<FancyContextProvider lang="en-US" formattingLang="en-US">{"..."}</FancyContextProvider>
		</div>
	);
};
`}
					</CodeBlock>
					<Paragraph>
						Alternatively, you can use the <Code>FancyRoot</Code> component which will apply the
						global styles (i.e. the <Code>.fancy-root</Code> class) for you.
					</Paragraph>
					<CodeBlock language="tsx">
						{`import React from "react";
import { FancyRoot } from "@siteimprove/fancylib";
import "@siteimprove/fancylib/global.css"; // Global styles for general styling

export const App: React.FC = () => {
	return <FancyRoot lang="en-US" formattingLang="en-US">{"..."}</FancyRoot>;
};`}
					</CodeBlock>
					<Header.H2>Translations</Header.H2>
					<Paragraph>
						Translations that are frequently used in components are available in the fancy context
						to keep the language consistent and avoid having many props only to provide text.
					</Paragraph>
					<Header.H3>Using translations</Header.H3>
					<Paragraph>
						To use translations from the fancy context, call the <Code>useTranslations</Code> method
						and use it as shown below:
					</Paragraph>
					<CodeBlock language="tsx">
						{`function Component(): JSX.Element {
	// get i18n function from the context and assign it to a variable
	const i18n = useTranslations();
	return (
		<Paragraph>
			{/* use a translation that's registered in the context */}
			{i18n.loading}
		</Paragraph>
	);
}`}
					</CodeBlock>
					<Header.H2>Static mode</Header.H2>
					<Paragraph>
						Interactive components can have an alternative appearance and behavior in a static
						setting that is used in places like the reports. If that is the case, you can check for{" "}
						<Code>staticMode</Code> using the method <Code>useStaticMode</Code> and conditionally
						render either the regular or the static version of the component.
					</Paragraph>
				</TextContainer>
			</ContentSection>
		</>
	);
};
